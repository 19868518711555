.ps__rail-x,
.ps__rail-y {
    z-index: 4;
}

.highcharts-tooltip-container {
    z-index: 99999 !important;
}

body:fullscreen {
    overflow: scroll !important;
}

body:-ms-fullscreen {
    overflow: scroll !important;
}

body:-webkit-full-screen {
    overflow: scroll !important;
}

body:-moz-full-screen {
    overflow: scroll !important;
}

.drag-handle {
    cursor: grab !important;
}

/* .drag-handle:hover {
    background-color: #f3eded;
} */

.react-resizable-handle {
    padding: 0;
    background-image: none;
}

.react-grid-item>.react-resizable-handle::after {
    right: 0;
    bottom: 0;
    width: 10px;
    height: 10px;
    border-color: rgba(0, 0, 0, 0.1);
}

.dashboard-editor .react-grid-item {
    border: 1px solid transparent;
    z-index: 1;
}

.dashboard-editor .react-grid-item:hover {
    border-color: rgba(0, 0, 0, 0.4);
    z-index: 3;
}

.dashboard-editor .selected-widget {
    border: 1px solid rgba(0, 0, 0, 0.8) !important;
    z-index: 2;
}

.dashboard-editor .selected-widget .widget-settings,
.dashboard-editor .react-grid-item:hover .widget-settings,
.dashboard-editor .react-draggable-dragging .widget-settings {
    display: flex !important;
    align-items: center;
    position: absolute;
    left: 4px;
    top: -28px;
    background-color: #323332;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 0 4px;
    z-index: 8;
}

.dashboard-editor .react-grid-item .widget-settings {
    display: none;
}



.dashboard-editor .addl-settings-icon .addl-settings {
    display: none;
}

.dashboard-editor .addl-settings-icon:hover .addl-settings {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 104px;
    padding: 10px 12px;
    position: absolute;
    top: 0px;
    right: -100px;
    background-color: #323332;
    border-radius: 0 6px 6px;
}

.react-grid-item:hover .react-resizable-handle::after {
    border-color: rgba(0, 0, 0, 0.4);
}


.react-grid-item>.react-resizable-handle.react-resizable-handle-e {
    margin-right: -2px;
    transform: none;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-w {
    margin-left: -2px;
    transform: rotate(180deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-n {
    margin-top: -2px;
    transform: rotate(270deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-s {
    margin-bottom: -2px;
    transform: rotate(90deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-se {
    margin-right: -1px;
    margin-bottom: -1px;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-ne {
    margin-right: -1px;
    margin-top: -1px;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-sw {
    margin-left: -1px;
    margin-bottom: -1px;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-nw {
    margin-left: -1px;
    margin-top: -1px;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-e::after {
    border-bottom: none;
    height: 100%;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-w::after {
    border-bottom: none;
    height: 100%;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-n::after {
    border-bottom: none;
    height: 100%;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-s::after {
    border-bottom: none;
    height: 100%;
}

*::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

*::-webkit-scrollbar:hover {
    background: #eee;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: #999;
    border-radius: 20px;
    border: 3px solid #fff;
}

*::-webkit-scrollbar-thumb:hover {
    border: none;
}

.dashboard-editor::-webkit-scrollbar:hover {
    background: transparent;
}

.dashboard-editor::-webkit-scrollbar-track {
    background: transparent;
}

.dashboard-editor::-webkit-scrollbar-thumb {
    background-color: #d1d0d0;
    border-radius: 20px;
    border: 3px solid #56585b;
}

.dashboard-editor::-webkit-scrollbar-thumb:hover {
    border: none;
}

.analytics-renderer::-webkit-scrollbar,
.analytics-renderer::-webkit-scrollbar-corner {
    background: #eef2f2;
}

.analytics-renderer::-webkit-scrollbar-thumb {
    background-color: #d3d0d0;
    border-radius: 20px;
    border: 3px solid #eee;
}

.analytics-renderer::-webkit-scrollbar-thumb:hover {
    border: none;
}

input[type="color"] {
    opacity: 0;
    display: block;
    width: 18px;
    height: 18px;
    border: none;
    cursor: pointer;
}

.ql-container {
    font-family: inherit;
    font-size: 14px;
    font-weight: 300;
}

.ql-editor {
    line-height: 1.5;
}

.ql-editor ol,
.ql-editor ul {
    padding-left: 0;
    padding-top: 2px;
}

.ql-toolbar.ql-snow {
    padding: 2px !important;
}

.ql-toolbar.ql-snow .ql-formats {
    margin-right: 4px !important;
}

.MuiTable-root .selection-checkbox {
    position: sticky !important;
    z-index: 1;
    left: 0;
    background-color: #fff;
}